import React, { useCallback, useMemo } from "react"; // Import useMemo
import Modal from "./index";
import Button from "@/components/button";
import { Dialog } from "@headlessui/react";
import { FiAlertTriangle } from "react-icons/fi";

// Wrap ConfirmDelete with React.memo
const ConfirmDelete = React.memo(({ isOpen, isLoading, onClose, onDelete }) => {
  // Memoize the isOpen and isLoading values
  const memoizedIsOpen = useMemo(() => isOpen, [isOpen]);
  const memoizedIsLoading = useMemo(() => isLoading, [isLoading]);

  // Memoize the onClose function
  const handleOnClose = useCallback(() => {
    onClose();
  }, [onClose]);

  // Memoize the onDelete function
  const handleOnDelete = useCallback(() => {
    onDelete();
  }, [onDelete]);

  return (
    <Modal
      isOpen={memoizedIsOpen} // Use memoized value
      onClose={handleOnClose} // Use memoized function
    >
      <div className="sm:flex sm:items-start">
        <div
          className="
                        mx-auto
                        flex
                        h-12
                        w-12
                        flex-shrink-0
                        rounded-full
                        items-center
                        justify-center
                        bg-red-100
                        sm:mx-0
                        sm:h-10
                        sm:w-10
                    "
        >
          <FiAlertTriangle className="h-6 w-6 text-red-600" />
        </div>
        <div
          className="
                        mt-3
                        text-center
                        sm:ml-4
                        sm:mt-0
                        sm:text-left
                    "
        >
          <Dialog.Title
            as="h3"
            className="
                            text-base
                            text-gray-900
                            font-semibold
                            leading-6
                        "
          >
            Hapus Data
          </Dialog.Title>
          <div className="mt-2">
            <p className="text-sm text-gray-500">
              Apakah anda yakin? Setelah dihapus anda tidak dapat
              mengembalikannya.
            </p>
          </div>
        </div>
      </div>
      <div
        className="
                    mt-5
                    sm:mt-4
                    sm:flex
                    sm:flex-row-reverse
                "
      >
        <Button
          type="button"
          disabled={memoizedIsLoading || false} // Use memoized value
          danger
          onClick={handleOnDelete} // Use memoized function
        >
          Hapus
        </Button>
        <Button
          type="button"
          color="text-gray-900"
          disabled={memoizedIsLoading || false} // Use memoized value
          onClick={handleOnClose} // Use memoized function
        >
          Batal
        </Button>
      </div>
    </Modal>
  );
});

export default ConfirmDelete;
