import React, { memo } from "react";
import Spinner from "./spinner";
import PropTypes from "prop-types";

// Wrap Spinner with React.memo
const MemoizedSpinner = memo(Spinner);

const LoadingButton = ({
  defaultLabel,
  loading = false,
  labelOnload = "Memproses...",
}) => {
  return (
    <>
      {loading ? (
        <>
          <MemoizedSpinner />
          <span className="ml-2 text-sm">{labelOnload}</span>
        </>
      ) : (
        <span className="text-sm">{defaultLabel}</span>
      )}
    </>
  );
};
LoadingButton.propTypes = {
  defaultLabel: PropTypes.string,
  loading: PropTypes.bool,
};

export default LoadingButton;
